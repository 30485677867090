<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!items">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..."/></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <b-card v-if="items">

            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="items"
                :search-options="{ enabled: false }"
                :pagination-options="{ enabled: false}"
                :sort-options="{ enabled: false }">
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <div v-if="props.column.field === 'subject'">
                        {{ types[props.row.subject] }}
                    </div>
                    <div v-else-if="props.column.field === 'message'">
                        {{ props.row.message }}
                    </div>
                    <div v-else-if="props.column.field === 'status'" :ref="'status'+props.row.id">
                        {{ props.row.status==0?'Görülmedi':'Görüldü' }}
                    </div>

                    <!-- Column: Action -->
                    <div v-else-if="props.column.field === 'action'">
                        <b-button-group>
                            <b-button variant="outline-primary" @click="setStatus(props.row.id, 1)" v-if="props.row.status==0">
                                <feather-icon icon="CheckIcon"/>
                            </b-button>
                            <b-button variant="outline-primary" @click="setStatus(props.row.id, 0)" v-else>
                                <feather-icon icon="XIcon"/>
                            </b-button>
                        </b-button-group>
                    </div>

                    <!-- Column: Common -->
                    <div v-else class="d-flex align-items-center">
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import {
    BAlert,
    BSpinner,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BButtonGroup,
    BButton,
    BForm,
    BRow,
    BCol


} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import formData from 'form-data'

export default {
    name: 'list',
    components: {
        BAlert,
        BSpinner,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
        BImg,
        BButtonGroup,
        BButton,
        BForm,
        BRow,
        BCol

    },
    data() {
        return {
            loading: true,
            items: false,
            preChange: {},
            columns: [
                {
                    label: '#',
                    field: 'id'
                },
                {
                    label: 'Konu',
                    field: 'subject'
                },
                {
                    label: 'Mesaj',
                    field: 'message'
                },
                {
                    label: 'Durum',
                    field: 'status'
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                    width: '160px',
                    align: 'center'
                },
            ],
            types: {
                '1' : 'Hata',
                '2' : 'Öneri',
                '3' : 'Şikayet',
            },
            titlePretty: {
                'app_name': 'Uygulama Adı',
                'app_slogan': 'Uygulama Sloganı',
                'company_name': 'Şirket Adı',
                'tax_office': 'Vergi Dairesi',
                'tax_number': 'Vergi Numarası',
                'address': 'Adres',
                'phone': 'Telefon',
                'gsm': 'Gsm',
                'whatsapp_number': 'Whatsapp Numarası',
                'version': 'Versiyon',
                'maintain': 'Bakım Modu',
                'default_veterinarian_km': 'Veteriner Varsayılan Arama Mesafesi (km)',
                'default_pet_hotel_km': 'Pet Otel Varsayılan Arama Mesafesi (km)',
                'default_cafe_km': 'Cafe Restaurant Varsayılan Arama Mesafesi (km)',
                'default_hotel_km': 'Otel Varsayılan Arama Mesafesi (km)',
                'default_pet_shop_km': 'Pet Shop Varsayılan Arama Mesafesi (km)',
                'default_training_center_km': 'Eğitim Merkezi Varsayılan Arama Mesafesi (km)',
            }
        }
    },
    created() {
        let t = this
        t.$http.get('/admin/suggestion/list')
            .then(res => {
                if (res.data.status) {
                    t.items = res.data.data
                } else if (res.data.message) {
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error => {
            console.log(error)
        }).then(() => {
            t.loading = false
        })

    },
    methods: {
        setStatus(id, status) {

            let t = this
            let fIndex = this.getItemIndex(id)

            t.$http.get(
                'admin/suggestion/setStatus/'+id+'/'+status,
            ).then((result) => {
                if (result.data.status) {
                    if (result.data.message) {
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }
                    t.items[fIndex].status = status
                    t.$refs['status'+id].html = status?'Görüldü':'Görülmedi'
                } else {
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message ? result.data.message : 'Veri Güncellenemedi. Lütfen daha sonra tekrar deneyin!.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                    t.resetItems(fIndex)
                }
            }).catch((error) => {
                console.log(error)

                t.$swal({
                    title: 'Hata!',
                    text: 'Veri Güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
                t.resetItems(fIndex)
            })

        },
        getItemIndex(id) {
            return this.items.findIndex(e => {
                return e.id === id
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.tableInput[readonly] {
    border: none !important;
}
</style>
